import { FunctionComponent } from "react";
import dayjs from "dayjs";

interface ContestProps {}

const Contest: FunctionComponent<ContestProps> = () => {
    return (
        <>
            <h5 style={{ textAlign: "center", fontSize: "18px" }}>
                <p>SAFEWAY 27&rsquo; MFX STAMPEDE ON-SITE CONTEST 2024</p>
            </h5>
            <>
                <ol>
                    <li>Eligibility</li>
                </ol>
                <p>
                    The Contest is open only to legal residents of Alberta (the “Participating Province”) who have reached the age of majority in their province of residence on the
                    date that they entered the Contest. Void where prohibited by law. Employees, directors, officers, representatives, agents of Sobeys Capital Incorporated (the
                    “Sponsor”), XMC Experiential Marketing and Sobeys Capital Incorporated (the “Administrator(s)”) and each of their respective parent companies, affiliates,
                    franchisees, subsidiaries, distributors, representatives, advertising and promotional agencies, agents, sponsors, and any entity that they contract with
                    specific to the&nbsp;Contest, as well as the immediate family members, and any persons domiciled with any of the above (whether related or not), are not
                    eligible to win a Prize.
                </p>
                <ol start={2}>
                    <li>Contest Period</li>
                </ol>
                <p>The Contest runs from July 4, 2024 at 5:00PM Mountain Daylight Time (“MDT”) and ends on July 7, 2024 at 11:59PM MDT (the "Contest Period").&nbsp;</p>
                <ol start={3}>
                    <li>How to Enter without Purchase</li>
                </ol>
                <p>
                    NO PURCHASE IS NECESSARY. To enter the Contest, visit the MFX activation trailer (the “MFX Trailer”) located at the Stampede Grounds in Calgary during the
                    Contest Period. Each attendee visiting the MFX Trailer and wishing to enter the Contest can scan the QR code with their own mobile device or use the device that
                    will be provided by a representative of the Sponsor or Administrator while onsite and complete the official Contest entry form (the “Entry Form”) by providing
                    your e-mail, first name, last name, checking off the corresponding boxes on the form to be entered, including e-flyer opt-in, read and accept the Contest Rules
                    to gain one (1) entry into the Contest for the chance to win (an “Online Entry”). One (1) bonus entry will be awarded if you add your Scene+ number when
                    completing the Entry Form (a “Bonus Entry” and together with the Online Entry, an “Entry(ies)”). An internet connection using data or Wi-Fi is required to
                    complete the steps above.
                </p>
                <p>Limit of one (1) Online Entry and one (1) Bonus Entry, per e-mail address, per person, during the Contest Period.</p>
                <ol start={4}>
                    <li>Prize Description</li>
                </ol>
                <p>
                    There are four (4) prizes to be won throughout the entire Contest Period. Each prize consists of one (1) Sobeys electronic gift card (each a “Prize”). The
                    approximate retail value of each Prize is $100.00 CAD.
                </p>
                <p>
                    <br /> The following conditions apply to the Prizes:
                </p>
                <ul>
                    <li>
                        The Prizes have no equivalent cash value, are non-transferable and no substitutions will be made. Prize must be accepted as awarded. The Sponsor reserves
                        the right to substitute any Prize for one of equal or greater value for any reason. You are responsible for all costs not expressly described as being
                        included as part of the Prize, such as any fee associated with the receipt and/or use of the Prize.&nbsp;
                    </li>
                    <li>You must sign the Sponsor’s release form (the “Release”) in order to receive and participate in the Prize.</li>
                    <li>
                        Sobeys gift cards have no expiration date and there are no inactivity fees or other fees associated with them. You can check your card balance in-store or
                        by calling 1-800-832-0717. Protect gift cards as if they were cash. A gift card's balance cannot be replaced if it is lost, stolen, damaged or used without
                        your consent. If a customer returns an item purchased using a gift card, the amount of the purchase will be returned to the customer's gift card, unless the
                        amount is less than $5. In this case, the customer will receive a cash refund. Sobeys Inc. gift cards are subject to the terms and conditions of use as
                        described on the website <a href="https://www.sobeys.com/en/gift-cards-every-occasion/">https://www.sobeys.com/en/gift-cards-every-occasion/</a>
                    </li>
                    <li>Gift card increments will be allocated at the Sponsor’s discretion.</li>
                    <li>Any difference between approximate retail value and the actual value of the Prize as taken will not be awarded.</li>
                </ul>
                <ol start={5}>
                    <li>Odds</li>
                </ol>
                <p>The odds of winning a Prize depends on the total number of eligible Entries received during the Contest Period.</p>
                <ol start={6}>
                    <li>Draw Details</li>
                </ol>
                <p>
                    Random draws will be conducted by a representative of the Sponsor or Administrator located in Calgary, Alberta, Canada, from all eligible Entries received
                    during the Contest Period (the “Draw”). You do not need to be in attendance to be selected. You are only eligible to win a Prize once. The Draws will occur in
                    accordance with the table below.
                </p>
                <table className="table">
                    <tbody>
                        <tr>
                            <td width={312}>
                                <p>
                                    <strong>Draw Date</strong>
                                </p>
                            </td>
                            <td width={312}>
                                <p>
                                    <strong>Number of Prizes Awarded </strong>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td width={312}>
                                <p>July 5, 2024</p>
                            </td>
                            <td width={312}>
                                <p>1 Prize</p>
                            </td>
                        </tr>
                        <tr>
                            <td width={312}>
                                <p>July 6, 2024</p>
                            </td>
                            <td width={312}>
                                <p>1 Prize</p>
                            </td>
                        </tr>
                        <tr>
                            <td width={312}>
                                <p>July 7, 2024</p>
                            </td>
                            <td width={312}>
                                <p>1 Prize</p>
                            </td>
                        </tr>
                        <tr>
                            <td width={312}>
                                <p>July 8, 2024</p>
                            </td>
                            <td width={312}>
                                <p>1 Prize</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <ol start={7}>
                    <li>Winner Contact Details</li>
                </ol>
                <p>
                    If you are chosen to be eligible to win a Prize, you will be contacted within fourteen (14) business days of the Draw at the telephone number, email address or
                    using the contact information that was submitted in the Entry. If you have not provided a telephone number or email address, you are not eligible to win the
                    Prize.&nbsp;In order to be declared a winner, you must correctly answer without assistance a mathematical skill testing question and sign a Release form. Upon
                    verification of the completion and correctness of the answer to the skill-testing question, and execution of the Release, you may be declared a winner. In case
                    of a dispute concerning the identity of the individual who submitted an Entry, the entrant will be deemed to be the account holder of the email address under
                    which the Entry was submitted.
                </p>
                <ol start={8}>
                    <li>Forfeit of Prize</li>
                </ol>
                <p>
                    If you cannot be reached within forty-eight (48) hours of our first attempt to contact you, do not answer the skill testing question correctly, or otherwise
                    fail to comply with these Contest Rules, then the Prize will be forfeited and another Entry will be randomly selected from among the remaining eligible Entries
                    as per these Contest Rules. This process of selecting a Prize winner will be repeated until all Prizes eligible to be won have been awarded.
                </p>
                <ol start={9}>
                    <li>Use of Personal Information</li>
                </ol>
                <p>
                    By entering the Contest and voluntarily providing your personal information as described in these Contest Rules, you agree to the collection, use and disclosure
                    by the Administrator, and its respective employees and/or authorized agents, of your personal information, for the purpose of administering the Contest,
                    including—but not limited to—contacting you with respect to the Contest if you are a selected entrant. Personally-identifiable information will only be used to
                    administer the Contest and for no other purpose, except as specifically outlined in these Contest Rules. Personally-identifiable information will not be sold,
                    shared or disclosed by the Administrator to any third party, other than to a third party engaged by the Administrator for the purpose of administering the
                    Contest, or if required by law with the exception that aggregated information may be used by or shared amongst the Administrator and its subsidiaries and
                    affiliates, or shared by the Administrator with third parties in accordance with our Privacy Commitment available at https://www.sobeys.com/en/privacy-policy.
                </p>
                <ol start={10}>
                    <li>Publicity Consent</li>
                </ol>
                <p>
                    By entering the Contest, you agree that if you are determined to be a winner, the Administrator and the Sponsor may use your name, comments, voice, likeness,
                    municipality of residence, your contest Entry and photo submission and picture in any advertising, promotion or publicity carried out now or in the future, in
                    any media without compensation or notice, and you grant to the Administrator and the Sponsor any and all rights to such use. You agree to cooperate with the
                    Administrator and the Sponsor in arranging for photographs or other forms of publicity and to be available for photographs or other forms of publicity on
                    reasonable notice.
                </p>
                <ol start={11}>
                    <li>Limitation of Liability / Release</li>
                </ol>
                <p>
                    By participating in the Contest, and as a condition of acceptance of the Prize, you, your heirs, executors, administrators, successors and assigns, release and
                    forever discharge and hold harmless the Sponsor and the Administrators, and each of their direct and indirect parent companies, affiliates, subsidiaries,
                    successors, assigns, agents, advisors, franchisees, shareholders, partners, representatives, their advertising, promotion and fulfillment agencies and each of
                    their respective employees, officers, directors, agents and representatives (collectively, the “Released Parties”), from and against any and all losses, damages
                    (including, without limitation, direct, indirect, incidental, consequential or punitive damages), rights, claims, actions, causes of action, personal injury,
                    property damage or death, including without limitation all costs and liabilities of any kind including legal fees on a substantial indemnity scale, hereinafter
                    called “Claims” and including without limitation any Claims arising from any act of negligence of the Released Parties, that you now have, or may hereafter have
                    against the Released Parties directly or indirectly resulting or arising from: (i) participation in the Contest, your Entry and/or the awarding, acceptance,
                    possession, use, or misuse or enjoyment of any Prize, and where applicable, traveling to, preparing for, or participating in, any Contest-related or
                    Prize-related event or activity; or (ii) the publicity rights granted to the Sponsor and the Administrator. You specifically acknowledge that you understand
                    that there may be serious risks of bodily injury, death, or property damage associated with the acceptance, possession, use and/or misuse of the Prize and
                    attendance at any event or participation in certain Prize-related activities, and you voluntarily assume these risks. You also agree to indemnify, defend, and
                    hold harmless the Released Parties from any and all Claims in respect of the foregoing.
                </p>
                <ol start={12}>
                    <li>Compliance with Rules</li>
                </ol>
                <p>
                    By entering the Contest, you agree to abide by the entire Contest Rules and the terms and conditions under which the Prize is awarded. Any decision made by the
                    Sponsor and/or the Administrators in respect of this Contest shall be final.
                </p>
                <ol start={13}>
                    <li>Technical Issues</li>
                </ol>
                <p>
                    The Sponsor and Administrators are not responsible for any computer, online, telephone, hardware, software or technical limitations or malfunctions that may
                    occur (including but not limited to malfunctions that may affect the transmission or non-transmission of an Entry, or failure to receive an Entry), nor for any
                    incorrect or inaccurate information, whether caused by website users or by any of the equipment or programming associated with or utilized in the Contest or by
                    any technical or human error which may occur in the processing of Entries in the Contest, nor for any error, omission, interruption, deletion, defect, delay in
                    operation or transmission or receipt of Entry, communications line failure, theft or destruction or unauthorized access to, or alteration of Entries; and, are
                    not responsible for any problems, failures or technical malfunction of any telephone or network lines, computer online systems, servers, providers, computer
                    equipment, software, email, players, or browsers, on account of technical problems or traffic congestion on the Internet, at any website or on account of any
                    combination of the foregoing; and are not responsible for any injury or damage to entrants or to any computer related injury or damage resulting from
                    participating in downloading materials in this Contest.
                </p>
                <p>
                    In the event that, due to a printing, manufacturing, mechanical or other error of any kind or nature whatsoever, more Prizes are claimed than are intended to be
                    awarded according to these Contest Rules, Prizes will stop being awarded when the Administrator becomes aware of an error respecting the number of Prizes and
                    the Administrator reserves the right, in their sole and absolute discretion, to conduct a random draw from amongst all eligible and approved Prize claimants
                    whose claims have not yet been redeemed to award the remaining number of advertised Prizes. In no event will the Sponsor or Administrator be liable for more
                    than the stated number of Prizes contained in these Contest Rules.
                </p>
                <ol start={14}>
                    <li>Rights to Terminate/Modify/Suspend Contest</li>
                </ol>
                <p>
                    The Administrators and Sponsor retain the right, in their absolute discretion, to suspend, modify or terminate any aspect of the Contest (including, but not
                    limited to these Contest Rules) at any time. The Administrator and Sponsor may make substitutions of equivalent kind or value in the event of the unavailability
                    of the Prize (or component thereof) for any reason whatsoever. The Administrator and Sponsor may terminate or withdraw this Contest at any time by posting a
                    notice on our website. If the Contest is terminated on any day prior to the normal Contest end date (the “Termination Date”), the Administrator and Sponsor may
                    determine the winners from all eligible Entries received as of 12:00PM MDT on the Termination Date (which winners shall be randomly selected in accordance with
                    these Contest Rules).
                </p>
                <ol start={15}>
                    <li>General Disqualification</li>
                </ol>
                <p>
                    Any attempt made by an entrant or other individual or entity, to deliberately damage, manipulate or undermine the legitimate operation of the Contest, including
                    but not limited to any fraudulent claims, is a violation of the law. The Administrator and Sponsor reserve the right to seek remedies and damages from any such
                    individual or entity which makes any such attempt. Any entrant who, in the opinion of the Administrator, is determined to be engaging in any of the foregoing
                    activities will be immediately disqualified from the Contest.
                </p>
                <ol start={16}>
                    <li>Role of Parties</li>
                </ol>
                <p>Sobeys Capital Incorporated is the Sponsor and XMC Experiential Marketing and Sobeys Capital Incorporated are the Administrators of this Contest.</p>
                <ol start={17}>
                    <li>Trademarks</li>
                </ol>
                <p>All other Trademarks are the property of their respective owners.</p>
                <ol start={18}>
                    <li>Jurisdiction</li>
                </ol>
                <p>
                    The Contest is governed by the laws of Alberta and the laws of Canada applicable therein, and is subject to all applicable federal, provincial and municipal
                    laws and regulations and is void where prohibited by law. Participation constitutes your full and unconditional agreement to these Contest Rules and the
                    decisions of the Sponsor and Administrator, which are final and binding in all matters related to the Contest. By entering the Contest, you agree that the
                    courts of Alberta, Canada shall have jurisdiction to entertain any action or other legal proceedings based on any provision of this Contest’s Rules or the
                    running of this Contest.
                </p>
            </>
        </>
    );
};

export default Contest;
